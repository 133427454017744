.file-upload-container {
    width: 100%;
    margin: 50px auto;
    padding: 20px;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  .file-input {
    margin-bottom: 20px;
  }
  
  .upload-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-button:hover {
    background-color: #45a049;
  }
  
  .file-list {
    margin-top: 20px;
  }
  
  .file-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .file-table th, .file-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .file-table th {
    background-color: #f2f2f2;
  }
  
  .file-row {
    animation: slideIn 0.5s ease-in-out;
  }
  
  .file-status.pending {
    color: #FF9800;
  }
  
  .file-status.uploaded {
    color: #4CAF50;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
   
  
  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }