.report-heading{ 
    font-size: 25px;
    }
    .filters
    {
        width: 100% !important;
    }
    
    .filter-row{
        display: flex;
        flex-wrap: wrap;
        width: 100% !important;
        
    }
    .filter-column{
        display: flex;
        flex-wrap: wrap;
        width: 30%;
        margin-left: 2%;
    }
    .filter-action
    {
        text-align: center ;
        width: 100% !important;
    }
    .report-data
    {
        width: 100% !important;
    }